/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* --- */

:root {
  --bg-color: #121212;
  --primary-color: #c8bb9b;
  --primary-highlight-color: #d8ccae;
  --overlay-color: #353535;
  --overlay-highlight-color: #484848;
  --header-font-family: "Bodoni 96", "Times New Roman", Times, serif;
  --small-font-family: "Bodoni MT", "Times New Roman", Times, serif;
  --body-font-family: "Jost", "Times New Roman", Times, serif;
}

nav {
  width: 100%;
}

body {
  background-color: var(--bg-color);
  color: var(--primary-color);
}

h3 {
  font-family: var(--body-font-family);
  font-size: 36pt;
  font-weight: 500;
  font-style: italic;
}

p {
  font-family: var(--body-font-family);
  font-size: 16pt;
  margin: 6px 0;
}

small {
  font-family: var(--small-font-family);
  font-size: 14pt;
  font-weight: 300pt;
}

@keyframes fadein {
  from {
    transform: translate3d(0px, 25px, 0px);
    opacity: 0;
  }

  to {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

section {
  width: 100%;
}

.logo {
  margin-top: 33vh;
  width: 100%;
  max-width: 430px;
  transition-property: all;
  animation: 500ms ease 0s normal forwards 1 fadein;
  opacity: 1;
}

.is-center {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  color: var(--primary-color);
  text-decoration-color: var(--primary-color);
  text-decoration: none;
  font-family: var(--body-font-family);
  font-size: 12pt;
  font-weight: 500;
  border-radius: 4px;
}

.icon {
  width: 60px;
  height: 60px;
  margin: 32px auto;
}

.button-primary {
  background-color: var(--overlay-color);
}

.button-primary:hover {
  background-color: var(--overlay-highlight-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.button-transparent:hover {
  color: var(--primary-highlight-color);
}

.button-outlined {
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  width: fit-content;
  padding: 6px 12px;
}

nav {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

nav .navbar {
  display: flex;
  flex-direction: row;
  padding: 32px;
}

nav .nav-item + .nav-item {
  margin-left: 2em;
}

nav .nav-item a {
  color: var(--primary-color);
  text-decoration-color: var(--primary-color);
  text-decoration: none;
  font-family: var(--body-font-family);
  font-size: 12pt;
  font-weight: 500;
}

nav .nav-item.main a {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 6px 12px;
}

/* Sections */
section {
  height: 100vh;
  background-color: var(--bg-color);
}

section.hero {
  background-color: transparent;
}

section.hero .container {
  padding: 1em 4em;
  margin-left: 33vh;
  animation: fadein 500ms ease-in;
}

section.hero .bg-hero {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0px;
  z-index: -1;
  background-image: url("../img/bg.png");
  background-size: cover;
}

section.hero .body {
  max-width: 560px;
  font-family: var(--body-font-family);
  font-size: 18pt;
  text-align: left;
  letter-spacing: 1px;
  padding: 1em 0;
}

section.hero .link-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

section.hero .link-list a + a {
  margin: 0 1em;
}

/* about-section */

section#about {
  background: linear-gradient(45deg, var(--bg-color), var(--overlay-color));
}

section#about .container {
  max-width: 500px;
  padding: 15% 0.5em 3em 0.5em;
  height: 100%;
}

section#about .description {
  position: sticky;
  top: 30px;
}

/* projects-section */

section#projects {
  padding: 35vh 0;
  background: url("../img/noise.png"),
    linear-gradient(
      to bottom,
      var(--primary-highlight-color),
      var(--primary-color)
    );
  color: white;
}

section#projects h3 {
  margin-bottom: 36px;
}

section#projects .project-section-header {
  font-family: var(--header-font-family);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

section#projects .project-list {
  margin: 0px auto 0 auto;
  max-width: 600px;
  display: flex;
}

section#projects .project-item {
  text-align: left;
  min-width: 200px;
  flex: 1;
}

section#projects .project-item .project-header {
  font-family: var(--body-font-family);
  font-size: 18px;
  font-weight: 500;
}

section#projects .project-item .project-body {
  font-family: var(--body-font-family);
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 12px;
}

footer {
  height: 60px;
  font-family: var(--body-font-family);
  background-color: var(--bg-color);
  font-size: 10pt;
  display: flex;
  align-items: flex-end;
  text-align: right;
  flex-direction: column;
  padding: 18px;
}

/* Mobile */

@media screen and (max-width: 1200px) {
  .logo {
    margin-top: 16vh;
  }

  nav {
    align-items: center;
  }

  nav .navbar {
    text-align: center;
    flex-direction: column;
  }

  nav .nav-item + .nav-item {
    margin: 12px 0 0 0;
  }

  section.hero .container {
    display: flex;
    flex-direction: column;
    margin: 1em;
    padding: 0;
    align-items: center;
    text-align: center;
  }

  section.hero .body {
    text-align: center;
    font-size: 14pt;
  }

  section#about .container {
    max-width: 500px;
    padding: 15% 0.5em 3em 0.5em;
    height: 100%;
  }

  section#projects {
    padding: 60px;
  }

  section#projects .project-list {
    flex-direction: column;
  }

  section#projects .project-item + .project-item {
    margin-top: 20px;
  }

  footer {
    align-items: center;
  }
}
